import * as React from 'react';
import Divider from '@mui/material/Divider';
import Hero from '../../components/Hero';
import Features from '../../components/Features';
import Testimonials from '../../components/Testimonials';
import Highlights from '../../components/Highlights';
import Pricing from '../../components/Pricing';
import FAQ from '../../components/FAQ';
import ContactUs from '../../components/ContactUs'; // Import the new ContactUs component
import Footer from '../../components/Footer';

export default function MarketingPage() {
  return (
    <div>
      <Hero />
      <div>
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <ContactUs />
        <Divider />
        <Footer />
      </div>
    </div>
  );
}
