import React, { use, useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import OutlinedInput from '@mui/material/OutlinedInput';
import styled from '@mui/material/styles/styled';
import { useAppContext } from '../contexts/AppContext';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const AddressForm = () => {

  const {appState, updateAppState} = useAppContext();

  const [address, setAddress] = useState({
    storeName: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    country: '',
    phone: ''
  });

  useEffect(() => {
    if (appState.newStoreAddress) {
      setAddress(appState.newStoreAddress);
    }

  }, []);

  useEffect(() => {
    updateAppState({newStoreAddress: address});
  }, [address]);

  const handlePhoneChange = (value: string) => {
    const phone = value.replace(/[^0-9+]/g, '');
    setAddress({...address, phone: phone});
  }

  return (
    <Grid container spacing={3}>
      <FormGrid size={{ xs: 12, md: 12, lg: 12 }}>
        <FormLabel htmlFor="store-name" required>
          Store name
        </FormLabel>
        <OutlinedInput
          id="store-name"
          name="store-name"
          type="name"
          placeholder="My store"
          autoComplete="store name"
          required
          size="small"
          onChange={(e) => setAddress({...address, storeName: e.target.value})}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="phone" required>
          Phone number
        </FormLabel>
        <OutlinedInput
          id="phone"
          name="phone"
          type="tel"
          placeholder="phone number"
          autoComplete="tel"
          required
          size="small"
          value={address.phone}
          onChange={(e) => handlePhoneChange(e.target.value)}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="address1" required>
          Address line 1
        </FormLabel>
        <OutlinedInput
          id="address1"
          name="address1"
          type="address1"
          placeholder="Street name and number"
          autoComplete="shipping address-line1"
          required
          size="small"
          onChange={(e) => setAddress({...address, address1: e.target.value})}
        />
      </FormGrid>
      <FormGrid size={{ xs: 12 }}>
        <FormLabel htmlFor="address2">Address line 2</FormLabel>
        <OutlinedInput
          id="address2"
          name="address2"
          type="address2"
          placeholder="Apartment, suite, unit, etc. (optional)"
          autoComplete="shipping address-line2"
          required
          size="small"
          onChange={(e) => setAddress({...address, address2: e.target.value})}
        />
      </FormGrid>
      <FormGrid size={{ xs: 4 }}>
        <FormLabel htmlFor="city" required>
          City
        </FormLabel>
        <OutlinedInput
          id="city"
          name="city"
          type="city"
          placeholder="New York"
          autoComplete="City"
          required
          size="small"
          onChange={(e) => setAddress({...address, city: e.target.value})}
        />
      </FormGrid>
      <FormGrid size={{ xs: 4 }}>
        <FormLabel htmlFor="zip" required>
          Zip / Postal code
        </FormLabel>
        <OutlinedInput
          id="zip"
          name="zip"
          type="zip"
          placeholder="12345"
          autoComplete="shipping postal-code"
          required
          size="small"
          onChange={(e) => setAddress({...address, zip: e.target.value})}
        />
      </FormGrid>
      <FormGrid size={{ xs: 4 }}>
        <FormLabel htmlFor="country" required>
          Country
        </FormLabel>
        <OutlinedInput
          id="country"
          name="country"
          type="country"
          placeholder="United States"
          autoComplete="shipping country"
          required
          size="small"
          onChange={(e) => setAddress({...address, country: e.target.value})}
        />
      </FormGrid>
    </Grid>
  );
}

export default AddressForm;