import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { GridCellParams, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

type SparkLineData = number[];

function getDaysInMonth(month: number, year: number) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

function renderSparklineCell(params: GridCellParams<SparkLineData, any>) {
  const data = getDaysInMonth(4, 2024);
  const { value, colDef } = params;

  if (!value || value.length === 0) {
    return null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <SparkLineChart
        data={value}
        width={colDef.computedWidth || 100}
        height={32}
        plotType="bar"
        showHighlight
        showTooltip
        colors={['hsl(210, 98%, 42%)']}
        xAxis={{
          scaleType: 'band',
          data,
        }}
      />
    </div>
  );
}

function renderActionType(status: 'Visit' | 'Reward') {
  const colors: { [index: string]: 'success' | 'default' } = {
    Visit: 'success',
    Reward: 'default',
  };

  return <Chip label={status} color={colors[status]} size="small" />;
}

export function renderAvatar(
  params: GridCellParams<{ name: string; color: string }, any, any>,
) {
  if (params.value == null) {
    return '';
  }

  return (
    <Avatar
      sx={{
        backgroundColor: params.value.color,
        width: '24px',
        height: '24px',
        fontSize: '0.85rem',
      }}
    >
      {params.value.name.toUpperCase().substring(0, 1)}
    </Avatar>
  );
}

export const columns: GridColDef[] = [
  { field: 'userName', headerName: 'User', flex: 1, minWidth: 100 },
  {
    field: 'actionType',
    headerName: 'Action Type',
    headerAlign: 'right',
    align: 'right',
    flex: 0.5,
    minWidth: 75,
    renderCell: (params) => renderActionType(params.value as any),
  },
  {
    field: 'totalPoint',
    headerName: 'Total Points',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actionTime',
    headerName: 'Action Time',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'storeName',
    headerName: 'Store',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 100,
  },
];

export const rows: GridRowsProp = [
  {
    id: 1,
    userName: 'f****c@gmail.com',
    actionType: 'Visit',
    totalPoint: 10,
    actionTime: '10:00 AM',
    storeName: 'Store 1',
  },
  {
    id: 2,
    userName: 'f****c@gmail.com',
    actionType: 'Reward',
    totalPoint: 20,
    actionTime: '11:00 AM',
    storeName: 'Store 2',
  },
  {
    id: 3,
    userName: 'f****c@gmail.com',
    actionType: 'Reward',
    totalPoint: 20,
    actionTime: '12:00 AM',
    storeName: 'Store 3',
  },
  {
    id: 4,
    userName: 'f****c@gmail.com',
    actionType: 'Reward',
    totalPoint: 20,
    actionTime: '13:00 AM',
    storeName: 'Store 4',
  },
  {
    id: 5,
    userName: 'f****c@gmail.com',
    actionType: 'Reward',
    totalPoint: 20,
    actionTime: '15:00 AM',
    storeName: 'Store 5',
  },
];
