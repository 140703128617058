// src/pages/auth/ChangePassword.js
import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Alert, CircularProgress } from '@mui/material';
import createAuthService from '../../utils/AuthService';
import { useAppContext } from '../../contexts/AppContext';

function ChangePassword() {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { appState, updateAppState } = useAppContext();
  var authService = createAuthService({ appState, updateAppState });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const email = searchParams.get('email');
    const token = searchParams.get('token');

    if (!email || !token) {
      setError('Invalid or missing email/token.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    var response = await authService.updatePasswordAsync(
      email,
      newPassword,
      token,
    );

    console.log(response);
    
    if (response.status !== 200) {
      setSuccess(false);
      setError(response.message);
      return;

    }

    setSuccess(true);
    updateAppState({
      isAuthenticated: true,
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken
    });

    authService = createAuthService({
      appState: {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken
      },
      updateAppState
    });

    var meResponse = await authService.meAsync();
    if (meResponse.status !== 200) {
      return;
    }

    updateAppState({
      user: meResponse.data,
    })

  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 10, p: 3, textAlign: 'center' }}>
      {!success ? (
        <>
          <Typography variant="h5" gutterBottom>
            Reset Your Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              margin="normal"
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update Password
            </Button>
          </form>
        </>
      ) : (
        <>
          <Alert severity="success" sx={{ mb: 2 }}>
            Password Updated Successfully
          </Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/home')}
          >
            Go to home page.
          </Button>
        </>
      )}
    </Box>
  );
}

export default ChangePassword;
