import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5179/Api';

export interface ApiInstanceParams {
  appState: any;
  updateAppState: any;
}

export const createApiInstance = ({ appState, updateAppState }: ApiInstanceParams) => {
  const instance = axios.create({ baseURL: BASE_URL, headers: { Authorization: `Bearer ${appState.accessToken}` } });

  instance.interceptors.request.use(
    (config) => {
      updateAppState({ loading: true });
      return config;
    },
    (error) => {
      updateAppState({ loading: false });
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      updateAppState({ loading: false });
      return response;
    },
    async (error) => {
      if (error.response?.status === 401 && appState.refreshToken) {
        try {
          const response = await instance.post('/users/refresh-token', {
            accessToken: appState.accessToken,
            refreshToken: appState.refreshToken,
          });

          const newAccessToken = response.data.AccessToken;
          updateAppState({ accessToken: newAccessToken });

          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return instance(error.config);
        } catch (refreshError) {
          updateAppState({
            error: 'Session expired. Please log in again.',
            accessToken: null,
            refreshToken: null,
          });
          return;
        }
      }

      updateAppState({ error: error.response?.data || 'An error occurred', loading: false });
      return Promise.reject(error);
    }
  );

  return instance;
};

// basic axios api without params and interceptors
export const Api = axios.create({ baseURL: BASE_URL });
