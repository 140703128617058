// src/App.js
import React, { useState } from 'react';
import { Toolbar, Box, Button, Drawer, List, ListItem } from '@mui/material'; // Removed ListItemText
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import CustomerManagement from './pages/customers/CustomerManagement';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import VerifyEmail from './pages/auth/VerifyEmail';
import ChangePassword from './pages/auth/ChangePassword';
import ValidateEmail from './pages/auth/ValidateEmail';
import LoadingOverlay from './components/LoadingOverlay'; // Import the loading overlay
import AppSnackbar from './components/ErrorSnackbar';
import MarketingPage from './pages/home/MarketingPage';
import { useAppContext } from './contexts/AppContext';
import StoreCreation from './pages/store-creation/StoreCreation';
import AppAppBar from './components/AppAppBar';
import AppTheme from './theme/AppTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from './pages/home/Dashboard';

const drawerWidth = 240;

function App() {

  const { appState } = useAppContext();

  return (
      <Router>
        <LoadingOverlay />
        <AppSnackbar />
        <AppTheme >
          <CssBaseline enableColorScheme />

          <AppAppBar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />

            <Routes>
              <Route
                path="/"
                element={appState.isAuthenticated ? <Navigate to="/home" replace /> : <MarketingPage />}
                />
              <Route
                path="/signin"
                element={appState.isAuthenticated ? <Navigate to="/home" replace /> : <SignIn />}
              />
              <Route
                path="/signup"
                element={appState.isAuthenticated ? <Navigate to="/home" replace /> : <SignUp />}>
              </Route>
              <Route
                path="/customers"
                element={appState.isAuthenticated ? <CustomerManagement /> : <Navigate to="/signin" replace />}
              />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/validate-email" element={<ValidateEmail />} />
              <Route 
                path="/home" 
                element={appState.isAuthenticated ? <Dashboard /> : <Navigate to="/signin" replace />} />
              <Route
                path="/store-creation"
                element={appState.isAuthenticated ? <StoreCreation /> : <Navigate to="/signin" replace />}>
              </Route>
            </Routes>
          </Box>
        </AppTheme>
      </Router>
  );
}

export default App;
