import { createApiInstance, ApiInstanceParams } from "./Api";
import { useAppContext } from "../contexts/AppContext";

const createAuthService = (params : ApiInstanceParams) => {

  const Api = createApiInstance(params);

  return {
    googleLoginAsync: async (accessToken: string) =>
      Api.post('/users/google-login', { accessToken }),

    emailLoginAsync: async (email: string, password: string) =>
      Api.post('/users/login', { email, password }),

    signUpAsync: async (userDetails: Record<string, any>) =>
      Api.post('/users', userDetails),

    forgotPasswordAsync: async (email: string) =>
      Api.get(`/users/refresh-password?email=${email}`),

    updatePasswordAsync: async (email: string, password: string, token: string) =>
      Api.post('/users/update-password', {email ,password, token }),

    validateEmailToken: async (token: string) =>
      Api.get(`/users/validate`, { params: { token } }),

    meAsync: async () =>
      Api.get('/users/me'),
  };
};

export default createAuthService;
