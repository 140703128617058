import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled, useColorScheme } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import { FacebookIcon, GoogleIcon } from '../../components/CustomIcons';
import createAuthService from '../../utils/AuthService';
import { useAppContext } from '../../contexts/AppContext';
import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function SignIn() {
  const [data, setData] = React.useState({});
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  // context init
  const { appState, updateAppState } = useAppContext();
  var authService = createAuthService({ appState, updateAppState });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleForgetSubmit = async (email) => {
    await authService.forgotPasswordAsync(email);
  };


  const handleGoogleLoginSuccess = async (credentialResponse) => {
    console.log(credentialResponse);
    const backendResponse = await authService.googleLoginAsync(credentialResponse.credential);
    updateAppState({
      isAuthenticated: true,
      accessToken: backendResponse.data.accessToken,
      refreshToken: backendResponse.data.refreshToken,
      success: `Welcome`,
    });

    authService = createAuthService({
      appState: {
        accessToken: backendResponse.data.accessToken,
        refreshToken: backendResponse.data.refreshToken
      },
      updateAppState
    });

    var meResponse = await authService.meAsync();
    if (meResponse.status !== 200) {
      return;
    }

    updateAppState({
      user: meResponse.data,
    });

    if (meResponse.data.associatedStoreIds === null ||
      meResponse.data.associatedStoreIds.length === 0) {
      //put  1 seconds delay here
      navigate('/store-creation');
      return;
    }

    navigate('/home');
  };

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

  const handleGoogleLoginError = () => {
    updateAppState({
      error: 'Google login failed',
    });
  };

  // Standard Login Handler
  const handleStandardLogin = async () => {

    if (!data.email || !data.password) {
      if (!data.email) {
        setEmailError(true);
        setEmailErrorMessage('Email is required');
      }
      if (!data.password) {
        setPasswordError(true);
        setPasswordErrorMessage('Password is required');
      }
      return;
    }
  
    const backendResponse = await authService.emailLoginAsync(data.email, data.password);

    if (backendResponse.status !== 200) {
      return;
    }

    updateAppState({
      isAuthenticated: true,
      accessToken: backendResponse.data.accessToken,
      refreshToken: backendResponse.data.refreshToken,
    });

    authService = createAuthService({
      appState: {
        accessToken: backendResponse.data.accessToken,
        refreshToken: backendResponse.data.refreshToken
      },
      updateAppState
    });

    var meResponse = await authService.meAsync();
    console.log(meResponse);

    if (meResponse.status !== 200) {
      return;
    }

    updateAppState({
      user: meResponse.data,
    });

    if (meResponse.data.associatedStoreIds === null ||
      meResponse.data.associatedStoreIds.length === 0) {

      navigate('/store-creation');
      return;
    }
    navigate('/home');
  };


  return (
    <SignInContainer direction="column" justifyContent="space-between">
      <Card variant="outlined">
        <Typography
          component="h1"
          variant="h4"
          sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
        >
          Sign in
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              onChange={(e) => setData({ ...data, email: e.target.value })}
              error={emailError}
              helperText={emailErrorMessage}
              id="email"
              type="email"
              name="email"
              placeholder="your@email.com"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              color={emailError ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              onChange={(e) => setData({ ...data, password: e.target.value })}
              error={passwordError}
              helperText={passwordErrorMessage}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="current-password"
              autoFocus
              required
              fullWidth
              variant="outlined"
              color={passwordError ? 'error' : 'primary'}
            />
          </FormControl>
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <ForgotPassword open={open} handleClose={handleClose} handleSubmit={handleForgetSubmit} />
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleStandardLogin()}
          >
            Sign in
          </Button>
          <Link
            component="button"
            type="button"
            onClick={handleClickOpen}
            variant="body2"
            sx={{ alignSelf: 'center' }}
          >
            Forgot your password?
          </Link>
        </Box>
        <Divider>or</Divider>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', justifySelf: 'center', alignSelf: 'center' }}>
            <GoogleLogin
              onSuccess={resp => handleGoogleLoginSuccess(resp)}
              onError={() => handleGoogleLoginError()}
              theme={'outline'}
              logo_alignment={'left'}
              shape={'rectangular'}
              size='large'
            />
          </Box>
          {/* <Button
            fullWidth
            variant="outlined"
            onClick={() => alert('Sign in with Facebook')}
            startIcon={<FacebookIcon />}
          >
            Sign in with Facebook
          </Button> */}
          <Typography sx={{ textAlign: 'center' }}>
            Don&apos;t have an account?{' '}
            <Link
              href="/signup"
              variant="body2"
              sx={{ alignSelf: 'center' }}
            >
              Sign up
            </Link>
          </Typography>
        </Box>
      </Card>
    </SignInContainer>
  );
}
