import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ContactUs() {
  const [formValues, setFormValues] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Submitted:', formValues);
    // TODO: Add backend integration
  };

  return (
    <Container
      id="contact"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          textAlign: 'center',
        }}
      >
        Contact Us
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '100%', sm: '70%' },
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          value={formValues.name}
          onChange={handleChange}
          required
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          value={formValues.email}
          onChange={handleChange}
          required
        />
        <TextField
          name="subject"
          label="Subject"
          variant="outlined"
          fullWidth
          value={formValues.subject}
          onChange={handleChange}
          required
        />
        <TextField
          name="message"
          label="Message"
          multiline
          rows={8} 
          variant="outlined"
          fullWidth
          value={formValues.message}
          onChange={handleChange}
          required
          sx={{
            '& .MuiInputBase-root': {
              minHeight: '150px', 
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            fontSize: '1rem',
            padding: '12px',
            mt: 2,
          }}
        >
          Send Message
        </Button>
      </Box>
    </Container>
  );
}
