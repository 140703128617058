// src/pages/auth/ValidateEmail.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Alert, CircularProgress, Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import createAuthService from '../../utils/AuthService';
import { useAppContext } from '../../contexts/AppContext';

function ValidateEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { appState, updateAppState } = useAppContext();
  var authService = createAuthService({ appState, updateAppState });
  const [status, setStatus] = useState(false);

  const validateToken = async () => {
    const token = searchParams.get('token');
    if (token) {
      var response = await authService.validateEmailToken(token);
      console.log(response);

      if (response.status !== 'success') {
        setStatus('error');
      }
      // set status to success before redirecting,
      updateAppState({
        isAuthenticated: true,
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken
      });

      authService = createAuthService({
        appState: {
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken
        },
        updateAppState
      });

      var response = await authService.meAsync();
      updateAppState({ user: response.data });

      setStatus('success');

    }
  };

  useEffect(() => {
    validateToken();
  }, []);
  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: 'auto',
        mt: 10,
        p: 3,
        boxShadow: 3,
        textAlign: 'center',
      }}
    >
      {status === 'loading' && (
        <>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Validating your email, please wait...
          </Typography>
        </>
      )}

      {status === 'success' && (
        <>
          <Alert severity="success">Email verified successfully!</Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/store-creation')}
          >
            Proceed to next step!
          </Button>
        </>
      )}

      {status === 'error' && (
        <>
          <Alert severity="error">Email verification failed. The token may be invalid or expired.</Alert>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate('/signup')}
          >
            Sign Up Again
          </Button>
        </>
      )}
    </Box>
  );
}

export default ValidateEmail;