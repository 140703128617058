import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomizedDataGrid from './CustomizedDataGrid';
import PageViewsBarChart from './PageViewsBarChar';
import SessionsChart from './SessionChart';
import { StatCardProps } from './StatCard';
import { Card, CardActionArea, CardContent, CardMedia, Paper, styled } from '@mui/material';

const data: StatCardProps[] = [
  {
    title: 'Customers',
    value: '14k',
    interval: 'Last 30 days',
    trend: 'up',
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
      360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
  {
    title: 'Store Actions',
    value: '325',
    interval: 'Last 30 days',
    trend: 'down',
    data: [
      50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
      190, 180, 170, 160, 150, 230, 210, 200, 190, 180, 170, 160, 150, 140
    ],
  },
  {
    title: 'Event count',
    value: '200k',
    interval: 'Last 30 days',
    trend: 'neutral',
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510, 530,
      520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
    ],
  },
];

const stores = [
  {
    name: 'Store 1',
    value: 200,
    photo: '/static/images/avatars/avatar_1.png',
    address: '1234 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 2',
    value: 150,
    photo: '/static/images/avatars/avatar_2.png',
    address: '5678 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 3',
    value: 100,
    photo: '/static/images/avatars/avatar_3.png',
    address: '9101 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 4',
    value: 50,
    photo: '/static/images/avatars/avatar_4.png',
    address: '1213 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 5',
    value: 200,
    photo: '/static/images/avatars/avatar_1.png',
    address: '1234 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 2',
    value: 150,
    photo: '/static/images/avatars/avatar_2.png',
    address: '5678 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 3',
    value: 100,
    photo: '/static/images/avatars/avatar_3.png',
    address: '9101 Main St, San Francisco, CA 94111',
  },
  {
    name: 'Store 4',
    value: 50,
    photo: '/static/images/avatars/avatar_4.png',
    address: '1213 Main St, San Francisco, CA 94111',
  }
]

const ScrollableContainer = styled(Grid)(({ theme }) => ({
  overflowX: 'auto', // Enable horizontal scrolling
  display: 'flex', // Use flexbox for horizontal layout
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  '&::-webkit-scrollbar': {
    height: '1em',
    padding: '1em',
    width: '5em',
    margin: '1em auto',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function MainGrid() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {/* {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 4 }}>
            <StatCard {...card} />
          </Grid>
        ))} */}
        {<ScrollableContainer>
          {stores.map((store, index) => (
            <Card sx={{
              maxWidth: 345,
              minWidth: 300, // Set a minimum width for consistent card sizes
              flex: '0 0 auto', // Ensure cards don't shrink or wrap
              marginRight: (theme) => theme.spacing(2), // Add spacing between cards
            }}>
              <CardMedia
                sx={{ height: 140 }}
                image="https://static.vecteezy.com/ti/gratis-vektor/p1/4705198-laden-icon-design-symbol-markt-einzelhandel-gebaude-schaufenster-fur-e-commerce-kostenlos-vektor.jpg"
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {store.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {store.address}
                </Typography>

              </CardContent>
              <CardActionArea>

              </CardActionArea>
            </Card>
          ))}
        </ScrollableContainer>
        }
        <Grid size={{ xs: 12, md: 6 }}>
          <SessionsChart />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Store Action Logs
      </Typography>
      <Grid container spacing={2} columns={12} >
        <Grid size={{ xs: 12, lg: 12 }}>
          <CustomizedDataGrid />
        </Grid>
      </Grid>
    </Box>
  );
}
