import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import AddressForm from '../../components/AddressForm';
import Review from '../../components/Review';
import RewardsForm from '../../components/RewardsForm';
import { useAppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import storeServiceCreator from '../../utils/StoreService';

const steps = ['Store details', 'Loyatly Offer(s)', 'Review'];

export default function StoreCreation(props: { disableCustomTheme?: boolean }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const { appState, updateAppState } = useAppContext();
  const navigate = useNavigate();
  const StoreService = storeServiceCreator({appState, updateAppState: updateAppState });

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <AddressForm />;
      case 1:
        return <RewardsForm />;
      case 2:
        return <Review />;
      default:
        throw new Error('Unknown step');
    }
  }

  
  const getStoreAdress = () => {
    return `${appState.newStoreAddress?.address1}, ${appState.newStoreAddress?.city}, ${appState.newStoreAddress?.state}, ${appState.newStoreAddress?.zip}, ${appState.newStoreAddress?.country}`;
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      var response = await StoreService.createStoreAsync({
        Name : appState.newStoreAddress?.storeName,
        Address : getStoreAdress(),
        Rewards : appState.newStoreRewards,
        PhoneNumber : appState.newStoreAddress?.phone
      })
      if(response.status !== 201) return;
      
      updateAppState({ address: undefined, newStoreRewards: [] });

    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const validateAddress = () => {
    // validate address
    if (
      appState === undefined ||
      appState.newStoreAddress === undefined ||
      appState.newStoreAddress?.storeName === '' ||
      appState.newStoreAddress?.address1 === '' ||
      appState.newStoreAddress?.city === '' ||
      appState.newStoreAddress?.state === '' ||
      appState.newStoreAddress?.zip === '' ||
      appState.newStoreAddress?.country === '') {
      return false;
    }

    return true;
  }

  const validateRewards = () => {
    // validate rewards
    if (appState.newStoreRewards?.length === 0) {
      return false
    }

    if(appState.newStoreRewards?.some((reward : any) => reward.name === '' || reward.points === 0)) {
      return false;
    }

    return true;
  }

  const validateStoreCreation = () => {
    // validate address 
    switch (activeStep) {
      case 0:
        return validateAddress();
      case 1:
        return validateRewards();
      case 2:
        return true;
      default:
    }

  }

  return (
      <Grid
        container
        sx={{
          height: {
            xs: '100%',
            sm: 'calc(100dvh - var(--template-frame-height, 0px))',
          },
          mt: {
            xs: 4,
            sm: 0,
          },
        }}
      >

        <Grid
          size={{ sm: 12, md: 12, lg: 12 }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            width: '100%',
            backgroundColor: { xs: 'transparent', sm: 'background.default' },
            alignItems: 'center',
            pt: { xs: 0, sm: 16 },
            px: { xs: 2, sm: 10 },
            gap: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'space-between', md: 'flex-end' },
              alignItems: 'center',
              width: '100%',
              maxWidth: { sm: '100%', md: 600 },
            }}
          >
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Stepper
                id="desktop-stepper"
                activeStep={activeStep}
                sx={{ width: '100%', height: 40 }}
              >
                {steps.map((label) => (
                  <Step
                    sx={{ ':first-child': { pl: 0 }, ':last-child': { pr: 0 } }}
                    key={label}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '100%',
              maxWidth: { sm: '100%', md: 600 },
              maxHeight: '720px',
              gap: { xs: 5, md: 'none' },
            }}
          >
            <Stepper
              id="mobile-stepper"
              activeStep={activeStep}
              alternativeLabel
              sx={{ display: { sm: 'flex', md: 'none' } }}
            >
              {steps.map((label) => (
                <Step
                  sx={{
                    ':first-child': { pl: 0 },
                    ':last-child': { pr: 0 },
                    '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } },
                  }}
                  key={label}
                >
                  <StepLabel
                    sx={{ '.MuiStepLabel-labelContainer': { maxWidth: '70px' } }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <Stack spacing={2} useFlexGap>
                <Typography variant="h1">🏪</Typography>
                <Typography variant="h5">Thank you for your request!</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  We will review your request and get back to you as soon as possible.
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ alignSelf: 'start', width: { xs: '100%', sm: 'auto' } }}
                  onClick={() => navigate('/stores')}
                >
                  Go to my stores.
                </Button>
              </Stack>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box
                  sx={[
                    {
                      display: 'flex',
                      flexDirection: { xs: 'column-reverse', sm: 'row' },
                      alignItems: 'end',
                      flexGrow: 1,
                      gap: 1,
                      pb: { xs: 12, sm: 0 },
                      mt: { xs: 2, sm: 0 },
                      mb: '160px',
                    },
                    activeStep !== 0
                      ? { justifyContent: 'space-between' }
                      : { justifyContent: 'flex-end' },
                  ]}
                >
                  {activeStep !== 0 && (
                    <Button
                      startIcon={<ChevronLeftRoundedIcon />}
                      onClick={handleBack}
                      variant="text"
                      sx={{ display: { xs: 'none', sm: 'flex' } }}
                    >
                      Previous
                    </Button>
                  )}
                  {activeStep !== 0 && (
                    <Button
                      startIcon={<ChevronLeftRoundedIcon />}
                      onClick={handleBack}
                      variant="outlined"
                      fullWidth
                      sx={{ display: { xs: 'flex', sm: 'none' } }}
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    disabled={!validateStoreCreation()}
                    variant="outlined"
                    endIcon={<ChevronRightRoundedIcon />}
                    onClick={handleNext}
                    sx={{ width: { xs: '100%', sm: 'fit-content' } }}
                  >
                    {activeStep === steps.length - 1 ? 'Send Store Creation Request' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
  );
}
