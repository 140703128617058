import * as React from 'react';
import { Box, Card, CardContent, Typography, OutlinedInput, FormLabel, Button, Slider } from '@mui/material';
import { Add, RemoveCircle} from '@mui/icons-material';
import { useAppContext } from '../contexts/AppContext';
import { useEffect } from 'react';
const RewardsForm = () => {
  const [rewards, setRewards] = React.useState<{ name: string; points: number }[]>([
    { name: '', points: 100 },
  ]);

  const { appState, updateAppState } = useAppContext();
  
  useEffect(() => {
    if (appState.newStoreRewards) {
      setRewards(appState.newStoreRewards);
    }
  }, []);

  useEffect(() => {
    updateAppState({ newStoreRewards: rewards });
  }, [rewards]);

  const handleAddReward = () => {
    if (rewards.length < 10) {
      setRewards([...rewards, { name: '', points: 100 }]); // Adds a new empty reward
    }
  };

  const handleRemoveReward = (index: number) => {
    const updatedRewards = rewards.filter((_, i) => i !== index);
    setRewards(updatedRewards); // Removes the reward at the specified index
  };

  const handleChangeReward = (index: number, field: 'name' | 'points', value: string | number | number[]) => {
    const updatedRewards = [...rewards];
    updatedRewards[index] = { ...updatedRewards[index], [field]: value };
    setRewards(updatedRewards);
  };

  function valuetext(value: number) {
    return `${value} Points`;
  }

  return (
    <Box>
      <Typography
        sx={{
          color: "red",
          fontSize: '0.875rem',
          mb: 2,
        }}
      >
        Users will get 10 points for every visit they made to your store.
      </Typography>

      <FormLabel htmlFor="reward-2" required>
        Define your offers:
      </FormLabel>

      {/* Reward cards */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {rewards.map((reward, index) => (
          <Card key={index}>
            <CardContent sx={{ position: 'relative' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Reward Name */}
                <Box>
                  <FormLabel htmlFor={`reward-name-${index}`} required>
                    Reward Name
                  </FormLabel>
                  <OutlinedInput
                    id={`reward-name-${index}`}
                    value={reward.name}
                    onChange={(e) => handleChangeReward(index, 'name', e.target.value)}
                    placeholder="Name of the offer, e.g., Free Coffee"
                    required
                    fullWidth
                  />
                </Box>

                {/* Reward Points */}
                <Box>
                  <FormLabel htmlFor={`reward-points-${index}`} required>
                    Points Required : {reward.points}
                  </FormLabel>
                  <Slider
                    aria-label="Points Required"
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    marks
                    step={10}
                    defaultValue={100}
                    min={10}
                    max={300}
                    color='success'
                    onChange={(e, value) => handleChangeReward(index, 'points', value)}
                  />
                </Box>
                <Button
                  variant="outlined"
                  disabled={rewards.length === 1}
                  onClick={() => handleRemoveReward(index)}
                  sx={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                  }}
                  endIcon={<RemoveCircle />}>
                    Remove
                </Button>
              </Box>

              {/* Remove reward button */}
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Button to add a new reward at the bottom */}
      <Box sx={{
        mt: 2,
        alignSelf: 'end',
        justifySelf: 'end',

      }}>

        <Button
          variant="outlined"
          onClick={handleAddReward}
          disabled={rewards.length >= 10}
          endIcon={<Add />}
        >
          Add Offer
        </Button>
      </Box>
    </Box>
  );
}

export default RewardsForm;