// src/components/ErrorSnackbar.js
import React from 'react';
import { Snackbar, Alert, IconButton } from '@mui/material';
import { useAppContext } from '../contexts/AppContext';
import { Close } from '@mui/icons-material';

const AppSnackbar = () => {
  const { appState, updateAppState } = useAppContext();

  const handleClose = () => {
    updateAppState({ error: null, success: null });
  };

  return (
    <Snackbar
      open={appState.error || appState.success}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        severity={appState.error ? 'error' : 'success'}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {appState.error || appState.success}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
