import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Context
const AppContext = createContext();

// Hook to use App Context
export const useAppContext = () => useContext(AppContext);

// Provider Component
export const AppProvider = ({ children }) => {
  const initialState = {
    loading: false,
    error: null,
    success: null,
    accessToken: '',
    refreshToken: '',
    user: null,
    isAuthenticated: false
  };

  const savedState = localStorage.getItem('appContextState');
  const [appState, setAppState] = useState(savedState ? JSON.parse(savedState) : initialState);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('appContextState', JSON.stringify(appState));
  }, [appState]);

  // Setter function to update the state
  const updateAppState = (updates) => {
    setAppState((prevState) => ({ ...prevState, ...updates }));
  };

  return (
    <AppContext.Provider value={{ appState, updateAppState }}>
      {children}
    </AppContext.Provider>
  );
};