import * as React from 'react';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-charts/themeAugmentation';
import type { } from '@mui/x-data-grid/themeAugmentation';
import type { } from '@mui/x-tree-view/themeAugmentation';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from '../../components/AppAppBar';
import Header from '../../components/Header';
import MainGrid from '../../components/MainGrid';
import AppTheme from '../../theme/AppTheme';
import {
  chartsCustomizations
} from '../../theme/customizations/charts';

import {
  dataGridCustomizations
} from '../../theme/customizations/dataGrid';

import {
  datePickersCustomizations
} from '../../theme/customizations/datePickers';

import {
  treeViewCustomizations
} from '../../theme/customizations/treeView';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props: { disableCustomTheme?: boolean }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          backgroundColor: alpha(theme.palette.background.default, 1),
          overflow: 'auto',
        })}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        >
          <MainGrid />
        </Stack>
      </Box>
    </Box>
  );
}
