import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from 'react';

interface ForgotPasswordProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (email: string) => void;
}

export default function ForgotPassword({ open, handleClose, handleSubmit }: ForgotPasswordProps) {
  const [message, setMessage] = useState("");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          if (message !== '') {
            handleClose();
            setMessage("");
            return;
          };
          event.preventDefault();
          let email = (event.target as HTMLFormElement).email.value;
          handleSubmit(email);
          setMessage("An email has been sent to you with a link to reset your password. Please check your inbox.");
        },
        sx: { backgroundImage: 'none' },
      }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        {!message && <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a link to
          reset your password.
        </DialogContentText>}
        {message && <DialogContentText>{message}</DialogContentText>}
        {message === "" && <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          fullWidth
        />}
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        {message === "" && <Button onClick={handleClose}>Cancel</Button>}
        <Button variant="contained" type="submit">
          {message == "" ? "Send Refresh Email" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
