import { createApiInstance, ApiInstanceParams } from "./Api";


const createStoreService = (params : ApiInstanceParams) => {

  const Api = createApiInstance(params);


  return {
    createStoreAsync: async (storeDetails: Record<string, any>) =>
      Api.post('/stores', storeDetails),

    getStoresAsync: async () =>
      Api.get('/stores'),

    updateStoreAsync: async (storeId: string, storeDetails: Record<string, any>) =>
      Api.put(`/stores/${storeId}`, storeDetails),
    
    deleteStoreAsync: async (storeId: string) =>
      Api.delete(`/stores/${storeId}`)
  };
};

//const StoreService = createStoreService();
export default createStoreService;
