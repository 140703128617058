import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled, useColorScheme } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import { FacebookIcon, GoogleIcon } from '../../components/CustomIcons';
import createAuthService from '../../utils/AuthService';
import { useAppContext } from '../../contexts/AppContext';
import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import RewardiouLogo from '../../components/RewardiouLogo';
import { error } from 'console';
import { useNavigate } from 'react-router-dom';
import { access } from 'fs';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
    height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
    minHeight: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

export default function SignUp(props: { disableCustomTheme?: boolean }) {
    const [data, setData] = React.useState({ firstName: '', lastName: '', email: '', password: '' });
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [nameErrorMessage, setNameErrorMessage] = React.useState('');
    const { appState, updateAppState } = useAppContext();
    const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

    var authService = createAuthService({ appState, updateAppState });
    const navigate = useNavigate();

    const handleGoogleLoginSuccess = async (credentialResponse: any) => {
        console.log(credentialResponse);
        const backendResponse = await authService.googleLoginAsync(credentialResponse.credential);
        updateAppState({
            isAuthenticated: true,
            accessToken: backendResponse.data.accessToken,
            refreshToken: backendResponse.data.refreshToken,
        });

        authService = createAuthService({
            appState: {
                accessToken: backendResponse.data.accessToken,
                refreshToken: backendResponse.data.refreshToken
            },
            updateAppState
        });

        var meResponse = await authService.meAsync();
        if (meResponse.status !== 200) {
            return;
        }
        updateAppState({
            user: meResponse.data,
            success: `Welcome ${meResponse.data.firstName}!`,
        });

        if (meResponse.data.associatedStoreIds === null ||
            meResponse.data.associatedStoreIds.length === 0) {
            navigate('/store-creation');
            return;
        }
    };

    const handleGoogleLoginError = () => {
        updateAppState({
            error: 'Google login failed',
        });
    };

    const handleSubmit = async (event: any) => {
        if (nameError || emailError || passwordError) {
            event.preventDefault();
            return;
        }

        var response = await authService.signUpAsync(data); // Use `signUpAsync` from `useAuthService`

        if (response.status > 201) {
            return;
        }

        setShowVerifyEmail(true);
    };

    return (
        <SignUpContainer direction="column" justifyContent="space-between">
            {!showVerifyEmail && <Card variant="outlined">
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                    Sign up
                </Typography>
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                >
                    <FormControl>
                        <FormLabel htmlFor="name">Full name</FormLabel>
                        <TextField
                            autoComplete="name"
                            name="name"
                            required
                            fullWidth
                            id="name"
                            placeholder="Jon Snow"
                            error={nameError}
                            helperText={nameErrorMessage}
                            color={nameError ? 'error' : 'primary'}
                            onChange={(e) => setData({ ...data, firstName: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            placeholder="your@email.com"
                            name="email"
                            autoComplete="email"
                            variant="outlined"
                            error={emailError}
                            helperText={emailErrorMessage}
                            color={passwordError ? 'error' : 'primary'}
                            onChange={(e) => setData({ ...data, email: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            placeholder="••••••"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            variant="outlined"
                            error={passwordError}
                            helperText={passwordErrorMessage}
                            color={passwordError ? 'error' : 'primary'}
                            onChange={(e) => setData({ ...data, password: e.target.value })}
                        />
                    </FormControl>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={(e) => handleSubmit(e)}
                    >
                        Sign up
                    </Button>
                </Box>
                <Divider>
                    <Typography sx={{ color: 'text.secondary' }}>or</Typography>
                </Divider>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ alignItems: 'center', justifyContent: 'center', justifySelf: 'center', alignSelf: 'center' }}>
                        <GoogleLogin
                            onSuccess={resp => handleGoogleLoginSuccess(resp)}
                            onError={() => handleGoogleLoginError()}
                            theme={'outline'}
                            logo_alignment={'left'}
                            shape={'rectangular'}
                            size='large'
                        />
                    </Box>
                    {/* <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => alert('Sign up with Facebook')}
                        startIcon={<FacebookIcon />}
                    >
                        Sign up with Facebook
                    </Button> */}
                    <Typography sx={{ textAlign: 'center' }}>
                        Already have an account?{' '}
                        <Link
                            href="/material-ui/getting-started/templates/sign-in/"
                            variant="body2"
                            sx={{ alignSelf: 'center' }}
                        >
                            Sign in
                        </Link>
                    </Typography>
                </Box>
            </Card>}
            {showVerifyEmail && <Box sx={{alignItems:'center', justifyItems: 'center'}} >
                <Typography variant="h1">📧</Typography>
                <Typography variant="h5">Thank you for your signing up!</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    Please verify your email address by clicking the link we sent to your email.
                </Typography>
            </Box>}
        </SignUpContainer>
    );
}
