import React from 'react';
import { useNavigate } from 'react-router-dom';

interface RewardiouLogoProps {
  height?: number;
  width?: number;
}

const RewardiouLogo: React.FC<RewardiouLogoProps> = ({ height = 50, width = 50 }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <img 
      src="https://rewardiou.com/images/Logo_Symbol_NB.png" 
      alt="Rewardiou Logo" 
      style={{ height, width, cursor: 'pointer' }}
      onClick={handleClick}
    />
  );
};

export default RewardiouLogo;