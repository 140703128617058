import * as React from 'react';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppContext } from '../contexts/AppContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const Review = () => {
  const { appState } = useAppContext();

  return (
    <Stack spacing={2}>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Store Name:" />
          <Typography variant="body2">{appState.newStoreAddress.storeName}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Store Contact" />
          <Typography variant="body2">{appState.newStoreAddress.phone}</Typography>
        </ListItem>
      <Divider />
        <ListItem sx={{ py: 1, px: 0, display: 'flex', flexDirection: 'column' }}>
          <ListItemText primary="Address" sx={{sm:12}} />
          <Typography variant="body2" sx={{sm:12, md:6}}>{appState.newStoreAddress.address1 + ', ' + appState.newStoreAddress.city + ', ' + appState.newStoreAddress.zip + ', ' + appState.newStoreAddress.country}</Typography>
        </ListItem>
      </List>
      <Divider />

      <Typography sx={{alignSelf:'center'}} variant="body2"  >Rewards</Typography>
      <TableContainer component={Paper} >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appState.newStoreRewards.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.points}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </Stack>
  );
}

export default Review;