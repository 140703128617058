import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter'; // X is still represented as TwitterIcon in Material UI

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="#">
        Rewardiou
      </Link>
      &nbsp;{new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 6 },
        py: { xs: 6, sm: 8 },
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr 1fr' },
          gap: { xs: 4, sm: 6 },
          justifyContent: 'center',
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 2 }}>
            Product
          </Typography>
          <Stack spacing={1} alignItems="center">
            <Link color="text.secondary" variant="body2" href="#features">
              Features
            </Link>
            <Link color="text.secondary" variant="body2" href="#testimonials">
              Testimonials
            </Link>
            <Link color="text.secondary" variant="body2" href="#highlights">
              Highlights
            </Link>
            <Link color="text.secondary" variant="body2" href="#pricing">
              Pricing
            </Link>
            <Link color="text.secondary" variant="body2" href="#faq">
              FAQs
            </Link>
            <Link color="text.secondary" variant="body2" href="#contact">
              Contact Us
            </Link>
          </Stack>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 2 }}>
            Company
          </Typography>
          <Stack spacing={1} alignItems="center">
            <Link color="text.secondary" variant="body2" href="#">
              About us
            </Link>
          </Stack>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 2 }}>
            Legal
          </Typography>
          <Stack spacing={1} alignItems="center">
            <Link color="text.secondary" variant="body2" href="#">
              Terms
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Privacy
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Contact
            </Link>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: { xs: 4, sm: 6 },
          width: '100%',
          maxWidth: '800px',
          borderTop: '1px solid',
          borderColor: 'divider',
          margin: '0 auto',
        }}
      >
        <Copyright />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/rewardiou"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ alignSelf: 'center' }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.twitter.com/rewardiou2024"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}
